import { Container, Flex, Heading, Image, Spacer } from "@chakra-ui/react";

const Header = () => {
  return (
    <Container maxW="full" bg="black" color="white" px={0} flex={1}>
      <Container maxW="1200px" px={0}>
        <Flex
          alignItems="center"
          height={{ base: "60px", md: "80px" }}
          px={{ base: 6, md: 8, xl: 0 }}
        >
          <Image src="/images/gemini-logo.png" w={{ base: 100, md: 157 }} />
          <Spacer />
          <Flex
            flex="1"
            justifyContent="flex-end"
            whiteSpace="nowrap"
            maxW={{ base: "unset", md: "fit-content" }}
          >
            <Heading
              fontSize={{ base: "20px", md: "36px", lg: "48px" }}
              fontWeight={900}
              letterSpacing="-0.05em"
            >
              NFT BILLBOARD NYC
            </Heading>
          </Flex>
        </Flex>
      </Container>
    </Container>
  );
};

export default Header;
