import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Spacer,
  Text,
  Image,
} from "@chakra-ui/react";

const About = () => {
  return (
    <Container
      maxW="1200px"
      my={120}
      px={0}
      id="about"
      borderBottom="1px dashed white"
      pb={70}
    >
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        px={{ base: 6, md: 8, xl: 0 }}
      >
        <Flex flexDir="column">
          <Heading as="h2" fontWeight={700} fontSize={24} mb={2}>
            About
          </Heading>
          <Text fontSize={18} mb={8}>
            The NFT Billboard NYC project was launched by{" "}
            <Link
              href="https://www.gemini.com/"
              isExternal
              textDecor="underline"
            >
              Gemini
            </Link>{" "}
            to celebrate the 13th anniversary of the Satoshi Bitcoin White Paper
            and kick off its advertising campaign in New York City. As part of
            the campaign, 105 words from the Satoshi Bitcoin White Paper
            abstract were projected on a digital billboard in Columbus Circle
            and were simultaneously available for auction.
          </Text>
          <Text fontSize={18}>
            All proceeds from the NFT billboard sale will be donated to the
            Human Rights Foundation’s (HRF) Bitcoin Development Fund. The HRF
            focuses on improving the privacy, usability, and resilience of the
            Bitcoin Network. Earlier this year Gemini launched the{" "}
            <Link
              href="https://www.gemini.com/opportunity-fund"
              isExternal
              textDecor="underline"
            >
              Gemini Opportunity Fund
            </Link>{" "}
            focused on making Bitcoin more scalable, secure, and sustainable
            through grants, fellowships, and donations.
          </Text>
        </Flex>
        <Spacer minWidth={150} />
        <Box>
          <Box flex={1} mt={{ base: 12, lg: 0 }} minW={{ base: 0, md: 472 }}>
            <Image src="/images/columbus-circle-map.png" maxW={500} w="100%" />
            <Text
              fontSize={18}
              textTransform="uppercase"
              fontWeight={600}
              mt={30}
            >
              3 Columbus Circle, New York, NY 10019
            </Text>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default About;
