import { Link, LinkProps } from "@chakra-ui/layout";

const DropInfoLink = (props: LinkProps) => (
  <Link
    fontSize="96px"
    lineHeight="116px"
    letterSpacing="-0.05em"
    fontWeight="bold"
    display="block"
    mt={2}
    color="black"
    _hover={{ textDecoration: "none" }}
    {...props}
  />
);

export default DropInfoLink;
