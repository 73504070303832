import { Text, TextProps } from "@chakra-ui/layout";

const DropInfoAttributeText = (props: TextProps) => (
  <Text
    fontSize={16}
    fontWeight={400}
    color="#626262"
    textTransform="uppercase"
    mb={1}
    {...props}
  />
);

export default DropInfoAttributeText;
