import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { drawBillboard, getBackgroundVideoSrc, getTimeIndex } from "../utils";
import { Nifty } from "../types";
import { useInterval } from "react-use";
import axios from "axios";

interface WeatherResponse {
  main: { temp: number };
}

const VideoContainer = ({ nifty }: { nifty?: Nifty }) => {
  const video = useRef<HTMLVideoElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const [temperature, setTemperature] = useState<number>();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const timeIndex = useRef<number>(getTimeIndex());
  const backgroundSample = useRef<number>(_.sample(_.range(5)) as number);
  const backgroundSrc = useRef<string>(
    getBackgroundVideoSrc(timeIndex.current, backgroundSample.current)
  );

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const { data } = await axios.get<WeatherResponse>(
          "https://api.openweathermap.org/data/2.5/weather?id=5128581&appid=a46a5bdd718734702e695a0ca8712343&units=imperial"
        );
        setTemperature(data?.main?.temp);
      } catch (e) {}
    };

    fetchWeather();
  }, []);

  useEffect(() => {
    const ctx = canvas.current?.getContext("2d");

    function handler() {
      if (ctx && video.current && nifty) {
        if (isVideoLoaded) {
          ctx.drawImage(video.current, 0, 0, 1920, 1080);
          drawBillboard(ctx, timeIndex.current, nifty, temperature);
        }
        requestAnimationFrame(handler);
      }
    }

    requestAnimationFrame(handler);
  }, [timeIndex, nifty, temperature, isVideoLoaded]);

  useInterval(() => {
    const nextTimeIndex = getTimeIndex();
    if (timeIndex.current !== nextTimeIndex) {
      timeIndex.current = nextTimeIndex;
      backgroundSrc.current = getBackgroundVideoSrc(
        timeIndex.current,
        backgroundSample.current
      );
      // Manually set and load
      video.current?.setAttribute("src", backgroundSrc.current);
      video.current?.load();
    }
  }, 1000);

  return (
    <Box
      position="absolute"
      top={{ base: "60px", xl: "80px" }}
      zIndex={-1}
      overflow="hidden"
      css={{
        "& > canvas": {
          position: "absolute",
          zIndex: 2,
          objectFit: "cover",
          height: "100%",
          width: "100%",
        },
        "@media only screen and (max-width: 960px)": {
          "& > canvas": {
            objectPosition: "25%",
          },
        },
        "& > video": {
          width: "100vw",
          height: "100vh",
          minWidth: 320,
          minHeight: 900,
          opacity: 0,
        },
      }}
    >
      <canvas ref={canvas} width={1920} height={1080} />
      <video
        ref={video}
        autoPlay
        loop
        muted
        playsInline
        onLoadedData={() => setIsVideoLoaded(true)}
      >
        <source src={backgroundSrc.current} type="video/mp4" />
        Video not supported.
      </video>
    </Box>
  );
};

export default VideoContainer;
