import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
});

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        fontFamily: "'Inter', sans-serif",
      },
    },
  },
  breakpoints,
  components: {
    Button: {
      baseStyle: {
        fontWeight: 400,
        textTransform: "uppercase",
      },
      defaultProps: {
        colorScheme: "black",
        variant: "outline",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
