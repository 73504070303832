import { Flex, FlexProps } from "@chakra-ui/layout";

const DropContainer = (props: FlexProps) => (
  <Flex
    mt={{ base: "180px", lg: "40px" }}
    mb={10}
    mx={{ base: 6, md: 8, xl: 0 }}
    p={{ base: 2, md: 3 }}
    borderRadius={16}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    background="white"
    {...props}
  />
);

export default DropContainer;
