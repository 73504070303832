import _ from "lodash";
import moment, { Moment } from "moment-timezone";
import { Nifty } from "./types";

const START_TS = "2021-10-25T09:00:00-04:00";

export const getThumbnail = (url: string) =>
  url.replace("upload/", "upload/so_10/").split(".mp4")[0] + ".jpg";

export const getPrimarySaleLink = (index: number) =>
  `https://niftygateway.com/itemdetail/primary/0x7c89bd7fa24b22da3c4657c266cac0fffa5311fc/${
    index + 1
  }`;

export const getBackgroundVideoSrc = (
  timeIndex: number,
  sampleIndex: number
) => {
  return `/backgrounds/${timeIndex}/${sampleIndex}.mp4`;
};

export const getTimeIndex = () => {
  const now = moment.tz("America/New_York");
  const hr = now.get("hour");

  /*
  0 morning — 6:00am - 9:00am
  1 afternoon — 9:00am - 5:00pm
  2 evening — 5:00pm - 9:00pm
  3 night — 9:00pm - 6:00am
  */

  if (hr >= 6 && hr < 9) return 0;
  if (hr >= 9 && hr < 17) return 1;
  if (hr >= 17 && hr < 21) return 2;

  return 3;
};

export const getNextAuctionTs = (): Moment => {
  const now = moment();
  const start = moment(START_TS);
  const index = _.range(105).find((i) => {
    return now < start.clone().add(i, "hour");
  });

  return _.isNumber(index) ? start.clone().add(index, "hour") : now;
};

export const getActiveAuctionIndex = (): number => {
  const now = moment();
  const start = moment(START_TS);
  // Iterate through n - 1 nifties
  const index = _.range(104).find((i) => {
    return now < start.clone().add(i + 1, "hour");
  });

  return typeof index === "undefined" ? 0 : index;
};

export const getActiveAuctionTs = (): Moment => {
  const now = moment();
  const start = moment(START_TS);
  // Iterate through n - 1 nifties
  const index = _.range(104).find((i) => {
    return now < start.clone().add(i + 1, "hour");
  });

  return typeof index === "undefined"
    ? start
    : start.clone().add(index, "hour");
};

export const drawBillboard = (
  ctx: CanvasRenderingContext2D,
  timeIndex: number,
  nifty?: Nifty,
  temperature?: number
) => {
  const now = moment.tz("America/New_York");
  const title = nifty ? nifty.niftyTitle.split(": ")[1] : "";
  // Trial and error scalar
  const scalar = 1.32;
  // Global padding top
  const gpt = [-16, -6, -13, 0][timeIndex];
  // Padding top for rectangle coverage only
  const pt = 13 + gpt;

  ctx.beginPath();

  // Custom paths to cover billboard
  if (timeIndex === 3) {
    ctx.moveTo(429 * scalar, 91 * scalar + pt);
    ctx.lineTo(561 * scalar, 108 * scalar + pt);
    ctx.lineTo(559 * scalar, 164 * scalar + pt);
    ctx.lineTo(424 * scalar, 147 * scalar + pt);
  } else if (timeIndex === 0) {
    ctx.moveTo(431 * scalar, 91 * scalar + pt);
    ctx.lineTo(570 * scalar, 110 * scalar + pt);
    ctx.lineTo(565 * scalar, 165.7 * scalar + pt);
    ctx.lineTo(424 * scalar, 149 * scalar + pt);
  } else if (timeIndex === 2) {
    ctx.moveTo(431 * scalar, 91 * scalar + pt);
    ctx.lineTo(568 * scalar, 110 * scalar + pt);
    ctx.lineTo(563 * scalar, 168 * scalar + pt);
    ctx.lineTo(424 * scalar, 150 * scalar + pt);
  } else if (timeIndex === 1) {
    ctx.moveTo(431 * scalar, 91 * scalar + pt);
    ctx.lineTo(572 * scalar, 110 * scalar + pt);
    ctx.lineTo(567 * scalar, 165.7 * scalar + pt);
    ctx.lineTo(424 * scalar, 149.2 * scalar + pt);
  }

  // Background colors per time of day
  ctx.fillStyle = ["#2b343e", "#212423", "#1f252d", "#52738a"][timeIndex];
  ctx.fill();
  ctx.save();

  ctx.translate(200, 200);
  // Rotate 7 degrees
  ctx.rotate((7 * Math.PI) / 180);

  // Text and shadow color
  const textColor = ["#f1f6fd", "#68706d", "#a5a8ac", "#d7dadd"][timeIndex];
  ctx.shadowColor = textColor;
  ctx.fillStyle = textColor;
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.shadowBlur = 3;

  ctx.font = "900 16px Inter";
  // Center text given text length
  ctx.fillText(title, 450 - (title.length - 1) * 5.6, -70 + gpt);

  ctx.font = "700 16px Inter";
  ctx.fillText(now.format("hh:mm A"), 362, -40 + gpt);
  if (temperature) {
    ctx.fillText(
      `${temperature?.toFixed(0)} ˚F`,
      [500, 505, 495, 492][timeIndex],
      -40 + gpt
    );
  }

  ctx.restore();
};
