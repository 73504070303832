import { Image, Box, Spinner, Flex } from "@chakra-ui/react";
import { useRef, useState } from "react";
import useVisibility from "../hooks/useVisibility";
import { Nifty } from "../types";
import { getThumbnail } from "../utils";

const Media = ({
  nifty,
  virtualize = false,
  preload = false,
  autoPlay = false,
}: {
  nifty: Nifty;
  virtualize?: boolean;
  preload?: boolean;
  autoPlay?: boolean;
}) => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isVisible, currentElement] = useVisibility<HTMLDivElement>(300, 100);
  const [isTouched, setIsTouched] = useState(false);
  const video = useRef<HTMLVideoElement>(null);
  const showMedia = !virtualize || (virtualize && isVisible) || autoPlay;

  return (
    <Box
      position="relative"
      ref={currentElement}
      height="100%"
      width="100%"
      cursor="pointer"
      onMouseLeave={(e) => setIsHovering(false)}
      onMouseEnter={(e) => {
        if (!isMediaLoaded) {
          video.current?.load();
        } else {
          if (video.current) video.current.currentTime = 0;
          video.current?.play();
        }
        setIsHovering(true);
      }}
      // Tap to play for mobile
      // Note: No functionality to end video, it'll stop on last frame / thumbnail
      onTouchStart={(e) => {
        e.preventDefault();
        if (!isMediaLoaded) {
          video.current?.load();
        } else {
          if (video.current) video.current.currentTime = 0;
          video.current?.play();
        }
        setIsTouched(true);
      }}
    >
      {(showMedia || isTouched) && (
        <>
          <Flex
            position="absolute"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            display={
              (isHovering || isTouched) && !isMediaLoaded ? "flex" : "none"
            }
            zIndex={0}
          >
            <Spinner color="white" />
          </Flex>
          <Image
            borderRadius={16}
            src={getThumbnail(nifty.niftyImageURL)}
            position="absolute"
            visibility={
              isHovering || isTouched || autoPlay ? "hidden" : "visible"
            }
            zIndex={1}
          />
          <video
            ref={video}
            onLoadedData={() => {
              setIsMediaLoaded(true);
              video.current?.play();
            }}
            muted
            autoPlay={autoPlay}
            preload={preload ? "auto" : "none"}
            playsInline
            style={{
              zIndex: 2,
              borderRadius: 16,
              visibility:
                (isHovering || isTouched || autoPlay) && isMediaLoaded
                  ? "visible"
                  : "hidden",
              position: "absolute",
            }}
          >
            <source src={nifty.niftyImageURL} type="video/mp4" />
          </video>
        </>
      )}
    </Box>
  );
};

export default Media;
