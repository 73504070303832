import { Flex, FlexProps } from "@chakra-ui/layout";

const DropInfoContainer = (props: FlexProps) => (
  <Flex
    flex="1"
    width="max-content"
    pt={{ base: 6, lg: 10 }}
    direction="column"
    justifyContent="center"
    alignItems="center"
    height={{ base: 327, lg: 336, xl: 408 }}
    {...props}
  />
);

export default DropInfoContainer;
