import { Container, Flex, Image, Spacer, Square, Text } from "@chakra-ui/react";
import { Moment } from "moment";
import { useRef, useState } from "react";
import { useInterval } from "react-use";
import About from "../components/About";
import ArrowIcon from "../components/ArrowIcon";
import DropContainer from "../components/DropContainer";
import DropInfoAttributeText from "../components/DropInfoAttributeText";
import DropInfoContainer from "../components/DropInfoContainer";
import DropInfoLink from "../components/DropInfoLink";
import Header from "../components/Header";
import Media from "../components/Media";
import PastDrop from "../components/PastDrop";
import VideoContainer from "../components/VideoContainer";
import data from "../data.json";
import { Nifty } from "../types";
import {
  getActiveAuctionIndex,
  getActiveAuctionTs,
  getPrimarySaleLink,
} from "../utils";

function App() {
  const nifties = data.nifties;
  const activeAuctionIndex = useRef<number>();

  const [activeAuctionTs, setActiveAuctionTs] = useState<Moment>(
    getActiveAuctionTs()
  );
  const [activeAuctionNifty, setActiveAuctionNifty] = useState<Nifty>();

  useInterval(() => {
    const i = getActiveAuctionIndex();
    if (activeAuctionIndex.current !== i) {
      activeAuctionIndex.current = i;

      setActiveAuctionNifty(nifties[i]);
      setActiveAuctionTs(getActiveAuctionTs());
    }
  }, 500);

  return (
    <Container
      maxW="full"
      minW={320}
      px={0}
      display="flex"
      flexDirection="column"
    >
      <VideoContainer nifty={activeAuctionNifty} />
      <Header />
      <Container maxW="1200px" px={0}>
        <Flex
          flex="1"
          flexDir={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "unset" }}
        >
          <Spacer />
          <DropContainer>
            <Square
              size={{ base: 310, lg: 403, xl: 456 }}
              rounded={{ base: 10, md: 14, xl: 16 }}
              bg="black"
            >
              {activeAuctionNifty && (
                <Media
                  key={activeAuctionIndex.current}
                  nifty={activeAuctionNifty}
                  preload
                  autoPlay
                />
              )}
            </Square>
            <DropInfoContainer>
              <Text
                fontSize={{ base: 18, md: 24, xl: 32 }}
                fontWeight={600}
                textTransform="uppercase"
                whiteSpace="nowrap"
              >
                {activeAuctionNifty?.niftyTitle}
              </Text>
              <DropInfoAttributeText>
                Released: {activeAuctionTs?.format("M/D/YYYY")}
              </DropInfoAttributeText>
              <DropInfoLink
                pl={46}
                href={getPrimarySaleLink(getActiveAuctionIndex())}
                isExternal
              >
                BUY <ArrowIcon top={3} left={-4} />
              </DropInfoLink>
            </DropInfoContainer>
          </DropContainer>
        </Flex>
      </Container>
      <Container maxW="full" color="white" bg="black" px={0} flex={1} pb={5}>
        <Container maxW="1200px" px={0}>
          <About />
          <Flex
            flexWrap="wrap"
            gridGap={{ base: 0, md: 12 }}
            justifyContent="center"
            flexDir={{ base: "column", md: "row" }}
          >
            {nifties.map((nifty: Nifty, i: number) => (
              <PastDrop key={nifty.id} nifty={nifty} index={i} />
            ))}
          </Flex>
          <Flex mt={150} px={10} flexDir={{ base: "column", md: "row" }}>
            <Image src="/images/gemini-logo.png" w={157} />
            <Spacer />
            <Text fontSize={14}>
              &copy; Copyright {new Date().getFullYear()} Gemini Trust Company,
              LLC.
            </Text>
          </Flex>
        </Container>
      </Container>
    </Container>
  );
}

export default App;
