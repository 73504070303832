import { Box, Text, Link, Flex } from "@chakra-ui/layout";
import { Square } from "@chakra-ui/react";
import ArrowIcon from "./ArrowIcon";
import { Nifty } from "../types";
import Media from "./Media";
import { getPrimarySaleLink } from "../utils";

const PastDrop = ({ nifty, index }: { nifty: Nifty; index: number }) => (
  <Flex
    flex={1}
    maxW={{ base: "full", md: 264 }}
    flexDir={{ base: "row", md: "column" }}
    borderBottomWidth={{ base: 1, md: 0 }}
    mx={{ base: 6, md: 0 }}
    py={{ base: 4, md: 0 }}
    borderStyle="dashed"
    borderColor="white"
    _last={{ borderWidth: 0 }}
    _first={{
      borderTopWidth: { base: 1, md: 0 },
      borderStyle: "dashed",
      borderColor: "white",
    }}
  >
    <Square
      size={{ base: "125px", md: "264px" }}
      borderWidth={1}
      borderColor="white"
      borderRadius={16}
    >
      <Media nifty={nifty} virtualize />
    </Square>
    <Box
      textAlign={{ base: "left", md: "center" }}
      mt={{ base: 0, md: 4 }}
      pl={{ base: 4, md: 0 }}
      flex={1}
    >
      <Text
        textTransform="uppercase"
        fontWeight={700}
        fontSize={{ base: 14, md: 18 }}
      >
        NFT Billboard NYC
      </Text>
      <Text
        textTransform="uppercase"
        fontWeight={400}
        fontSize={{ base: 14, md: 18 }}
      >
        {nifty.niftyTitle}
      </Text>
      <Box mt={{ base: 1, md: 4 }}>
        <Box
          borderBottomWidth={{ base: 0, md: 1 }}
          borderStyle="dashed"
          borderColor="white"
        />
        <Link
          fontSize={{ base: 28, md: 36 }}
          fontWeight={600}
          display="block"
          position="relative"
          mt={{ base: 1, md: 2 }}
          _hover={{ textDecoration: "none" }}
          isExternal
          href={getPrimarySaleLink(index)}
        >
          BUY{" "}
          <ArrowIcon
            fill="white"
            size={{ base: 30, md: 46 }}
            top={{ base: 1, md: 0 }}
            left={{ base: -1, md: 0 }}
          />
        </Link>
      </Box>
    </Box>
  </Flex>
);

export default PastDrop;
