import { Box, BoxProps } from "@chakra-ui/layout";
import styled from "@emotion/styled";
import { ReactComponent as Icon } from "../images/icons/arrow-down.svg";

const StyledIcon = styled(Icon)`
  path {
    fill: ${(p) => p.fill};
  }
`;

const ArrowIcon = (
  props: BoxProps & {
    fill?: string;
    size?: number | Record<string, string | number>;
  }
) => {
  const { fill = "black", size = 46, ...restProps } = props;
  return (
    <Box
      display="inline-block"
      verticalAlign="top"
      position="relative"
      transform="rotate(-135deg)"
      height={size}
      width={size}
      {...restProps}
    >
      <StyledIcon fill={fill} />
    </Box>
  );
};

export default ArrowIcon;
